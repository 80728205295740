<template>
  <v-list-item
    :class="{'white elevation-2 mb-3 rounded-lg': $vuetify.breakpoint.xsOnly}"
    @click="$emit('click')"
  >
    <v-list-item-content>
      <v-row>
        <v-col
          :cols="$vuetify.breakpoint.xsOnly ? '12' : '5'"
          class="py-5">
          <template v-if="$vuetify.breakpoint.xsOnly">
            <div class="d-flex justify-space-between">
              <div class="d-flex align-center">
                <v-icon size="18" left>mdi-calendar-month-outline</v-icon>
                <span class="blue-grey--text">{{data_visita.fecha | smartDate }}</span>
              </div>
              <v-chip
                class="ma-2 font-weight-bold text-uppercase caption"
                color="success-alert"
                style="overflow: visible !important"
                small
                label
                pill
                dark
              >
                {{ data_visita.tipo_visita }}
              </v-chip>
            </div>
            <v-divider class="mb-4" />
          </template>
          <v-list-item-title
            class="primary--text font-weight-medium pb-2"
            :class="{'text-wrap': $vuetify.breakpoint.xsOnly}"
            v-text="data_visita.co_cli + ' - ' + data_visita.cli_des"
          />
          <v-list-item-subtitle
            class="d-flex align-center pt-1"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="19"
                  class="mr-2"
                  v-text="data_visita.fotos === null ? 'mdi-camera-off' : 'mdi-camera'"
                  :color="data_visita.fotos === null ? 'blue-grey lighten-4' : 'blue-grey lighten-1'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span  v-text="data_visita.fotos === null ? 'No tiene imagén Registrada' : 'Tiene Imagenes Registradas'"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                  <v-icon
                  size="19"
                  class="mr-2"
                  :color="data_visita.pedido === true ? 'blue-grey lighten-1' : 'blue-grey lighten-4'"
                  v-text="data_visita.pedido === true ? 'mdi-text-box-check-outline' : 'mdi-text-box-remove-outline'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span  v-text="data_visita.pedido === false ? 'No tiene Pedido Registrado' : 'Pedido Registrado'"></span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  size="19"
                  class="mr-2"
                  v-text="data_visita.lat_cliente !== null && data_visita.lng_cliente !== null ? 'mdi-map-marker-radius-outline' : 'mdi-map-marker-off-outline'"
                  :color="data_visita.lat_cliente !== null && data_visita.lng_cliente !== null ? 'blue-grey lighten-1' : 'blue-grey lighten-4'"
                  v-bind="attrs"
                  v-on="on"
                />
              </template>
              <span  v-text="data_visita.lat_cliente !== null && data_visita.lng_cliente !== null ? 'Cliente Geolocalizado' : 'Cliente sin Geolocalización'"></span>
            </v-tooltip>
            <div
              v-if="$vuetify.breakpoint.xsOnly"
              class="mr-2 d-flex align-center"
            >
              <v-icon
                size="19"
                color="blue-grey lighten-1"
                class="mr-1"
              >
                mdi-alarm-check
              </v-icon>
              <span class="font-weight-medium primary--text">
                {{ calcularDuracion(data_visita) }}
              </span>
            </div>
            <v-chip
              v-if="data_visita.lat_cliente !== null && data_visita.lng_cliente !== null"
              small
              color="grey lighten-3"
              class="px-2 py-3 d-flex align-center"
            >
              <v-icon
                size="16"
                class="mr-1"
                :color="dist_status.color"
              >mdi-map-marker</v-icon>
              <span
                class="font-weight-bold display"
                :class="`${dist_status.color}--text`"
              >
                {{ dist_status.text }}
              </span>
            </v-chip>
          </v-list-item-subtitle>
          <div
             v-if="$vuetify.breakpoint.xsOnly"
            class="mt-4"
          >
            <span
              class="text-center blue-grey--text font-weight-regular text-subtitle-2"
            >
              <strong>Motivo: </strong>{{data_visita.motivo}}
            </span>
          </div>
        </v-col>
        <template v-if="$vuetify.breakpoint.smAndUp">
          <v-col cols="3" class="d-flex align-center justify-center">
            <span
              class="text-center blue-grey--text font-weight-regular"
              v-text="data_visita.motivo !== null ? data_visita.motivo : 'S/M'"
            />
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-list-item-title class="d-flex flex-row align-center justify-center text-wrap">
              <!-- <v-icon
                size="19"
                color="icono"
                left
              >
                mdi-clock-outline
              </v-icon>
              <span>
                {{ data_visita.fecha + ' ' + data_visita.hora_inicio | horaFormat }}
              </span>
              <v-icon
                size="19"
                color="red"
                left
                class="ml-3"
              >
                mdi-clock-outline
              </v-icon>
              <span>
                {{ data_visita.fecha + ' ' + data_visita.hora_fin | horaFormat }}
              </span> -->
              <v-icon
                size="19"
                color="blue-grey darken-2"
                class="ml-3"
                left
              >
                mdi-alarm-check
              </v-icon>
              <span>
                {{ calcularDuracion(data_visita) }}
              </span>
            </v-list-item-title>
          </v-col>
           <v-col cols="2" class="d-flex align-center justify-center">
              <v-chip
              class="ma-2 font-weight-bold text-uppercase"
              color="success-alert"
              style="overflow: visible !important"
              small
              label
              pill
              dark
            >
              {{ data_visita.tipo_visita }}
            </v-chip>
          </v-col>
        </template>
      </v-row>
    </v-list-item-content>
    <v-list-item-action
      v-if="$vuetify.breakpoint.smAndUp"
      class="d-flex flex-row align-center justify-center"
    >
      <v-icon size="18" left>mdi-calendar-month-outline</v-icon>
      <span class="blue-grey--text">{{data_visita.fecha | smartDate }}</span>
    </v-list-item-action>
  </v-list-item>
</template>
<script>
import moment from 'moment'
import { gmapApi } from 'gmap-vue'
export default {
  name: 'HistoriaVisita',
  props: {
    data_visita: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dist_metros: '',
      dist_status: {
        text: '',
        color: '',
      },
    }
  },
  computed: {
    googleApi: gmapApi,
  },
  watch: {
    data_visita (val) {
      this.$gmapApiPromiseLazy().then(() => {
        if(val.lat_cliente !== null && val.lng_cliente !== null) {
          var visita = new this.googleApi.maps.LatLng(parseFloat(val.lat.trim()), parseFloat(val.lng.trim()));
          var cliente = new this.googleApi.maps.LatLng(parseFloat(val.lat_cliente.trim()), parseFloat(val.lng_cliente.trim()));

          this.dist_metros = this.googleApi.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);

          this.dist_status = this.dist_metros < 100
          ? {text: 'D.R', color: 'icono' }
          : this.dist_metros > 100 && this.dist_metros < 300
          ? { text: 'S.R', color: 'warning' }
          : this.dist_metros > 300 ? {text: 'F.L', color: 'red' }
          : null
        }
      })
    }
  },
  mounted () {
    this.$gmapApiPromiseLazy().then(() => {
      if(this.data_visita.lat_cliente !== null && this.data_visita.lng_cliente !== null) {
        var visita = new this.googleApi.maps.LatLng(parseFloat(this.data_visita.lat.trim()), parseFloat(this.data_visita.lng.trim()));
        var cliente = new this.googleApi.maps.LatLng(parseFloat(this.data_visita.lat_cliente.trim()), parseFloat(this.data_visita.lng_cliente.trim()));

        this.dist_metros = this.googleApi.maps.geometry.spherical.computeDistanceBetween(visita, cliente).toFixed(2);

        this.dist_status = this.dist_metros < 100
        ? {text: 'D.R', color: 'icono' }
        : this.dist_metros > 100 && this.dist_metros < 300
        ? { text: 'S.R', color: 'warning' }
        : this.dist_metros > 300 ? {text: 'F.L', color: 'red' }
        : null
      }
    })
  },
  methods:{
    calcularDuracion (item) {
      const inicio = moment(`${item?.fecha} ${item?.hora_inicio}`);
      const fin = moment(`${item?.fecha} ${item?.hora_fin}`);
      const duracion = fin.diff(inicio)

      const hours = Math.floor(moment.duration(duracion).asHours()).toString()
      return `${ hours.length < 2 ? hours.padStart(2, '0') : hours }:${ moment.utc(duracion).format('mm:ss') }`
    },
  },
  filters: {
    horaFormat (val) {
      return moment(val).format('hh:mm:ss a');
    }
  },
}
</script>
